/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

import { FC, memo, useState, useEffect } from 'react';
import {
  Navbar,
  Container,
  Nav,
  Form,
  FormControl,
  Button,
  Offcanvas,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  useSearchParams,
  Link,
  useNavigate,
  useLocation,
  useMatch,
  NavLink,
} from 'react-router-dom';

import classnames from 'classnames';

import {
  loggedUserInfoStore,
  siteInfoStore,
  brandingStore,
  themeSettingStore,
  sideNavStore,
} from '@/stores';
import { logout, useQueryNotificationStatus } from '@/services';
import { Avatar, Icon } from '@/components';

import './index.scss';

const Header: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = useLocation();
  const [urlSearch] = useSearchParams();
  const q = urlSearch.get('q');
  const { user, clear: clearUserStore } = loggedUserInfoStore();
  const { t } = useTranslation();
  const [searchStr, setSearch] = useState('');
  const siteInfo = siteInfoStore((state) => state.siteInfo);
  const brandingInfo = brandingStore((state) => state.branding);
  const { updateReview } = sideNavStore();
  const [showNav, changeShowNav] = useState(false);
  const { data: redDot } = useQueryNotificationStatus();
  /**
   * Automatically append `tag` information when creating a question
   */
  const tagMatch = useMatch('/tags/:slugName');
  let askUrl = '/questions/ask';
  if (tagMatch && tagMatch.params.slugName) {
    askUrl = `${askUrl}?tags=${tagMatch.params.slugName}`;
  }

  useEffect(() => {
    updateReview({
      can_revision: Boolean(redDot?.can_revision),
      revision: Number(redDot?.revision),
    });
  }, [redDot]);

  const handleInput = (val) => {
    setSearch(val);
  };
  const handleSearch = (evt) => {
    evt.preventDefault();
    if (!searchStr) {
      return;
    }
    const searchUrl = `/search?q=${encodeURIComponent(searchStr)}`;
    navigate(searchUrl);
  };

  const handleLogout = async () => {
    await logout();
    clearUserStore();
    window.location.replace(window.location.href);
  };

  const getMenuUrl = (path) => {
    if (window.location.host === 'answer.eegames.com') {
      return `https://www.eegames.com${path}`;
    }
    return `http://www.pre.eegames.com${path}`;
  };

  useEffect(() => {
    if (q && location.pathname === '/search') {
      handleInput(q);
    }
  }, [q]);

  useEffect(() => {
    const collapse = document.querySelector('#navBarContent');
    if (collapse && collapse.classList.contains('show')) {
      const toggle = document.querySelector('#navBarToggle') as HTMLElement;
      if (toggle) {
        toggle?.click();
      }
    }

    // clear search input when navigate to other page
    if (location.pathname !== '/search' && searchStr) {
      setSearch('');
    }
  }, [location.pathname]);

  let navbarStyle = 'theme-colored';
  const { theme, theme_config } = themeSettingStore((_) => _);
  if (theme_config?.[theme]?.navbar_style) {
    navbarStyle = `theme-${theme_config[theme].navbar_style}`;
  }

  const handleTabClick = (e, path) => {
    e.preventDefault();
    navigate(path);
  };

  const handleNavClick = (e, path) => {
    e.preventDefault();
    changeShowNav(false);
    navigate(path);
  };

  return (
    <div className="ee-header">
      <Navbar
        variant={navbarStyle === 'theme-colored' ? 'dark' : ''}
        expand="lg"
        className={classnames('sticky-top', navbarStyle)}
        id="header">
        <Container className="d-flex align-items-center ">
          <div className="d-flex justify-content-between align-items-center nav-grow flex-nowrap">
            <Navbar.Brand to="/" as={Link} className="lh-1 me-0 me-sm-5 p-0">
              {brandingInfo.logo ? (
                <>
                  <img
                    className="d-none d-lg-block logo me-0"
                    src={brandingInfo.logo}
                    alt={siteInfo.name}
                  />

                  <img
                    className="lg-none logo me-0"
                    src={brandingInfo.mobile_logo || brandingInfo.logo}
                    alt={siteInfo.name}
                  />
                </>
              ) : (
                <span>{siteInfo.name}</span>
              )}
            </Navbar.Brand>

            {/* mobile nav */}
            <Navbar.Toggle
              aria-controls="navBarContent"
              className="answer-navBar text-white"
              id="navBarToggle"
              onClick={() => {
                changeShowNav(true);
              }}>
              <Icon name="list" className="fs-4" />
            </Navbar.Toggle>
          </div>

          <Navbar.Collapse />
        </Container>
      </Navbar>
      <Nav variant="pills" className="ee-mini-nav">
        <NavLink
          to="/questions"
          className={({ isActive }) =>
            isActive || pathname === '/' ? 'nav-link active' : 'nav-link'
          }>
          <Icon name="question-circle-fill" className="me-2" />
          <span>{t('header.nav.question')}</span>
        </NavLink>

        <Nav.Link
          href="/tags"
          active={pathname === '/tags'}
          onClick={(e) => handleTabClick(e, '/tags')}>
          <Icon name="tags-fill" className="me-2" />
          <span>{t('header.nav.tag')}</span>
        </Nav.Link>

        <NavLink to="/users" className="nav-link">
          <Icon name="people-fill" className="me-2" />
          <span>{t('header.nav.user')}</span>
        </NavLink>
      </Nav>
      <Offcanvas
        show={showNav}
        onHide={() => changeShowNav(false)}
        placement="end"
        className="ee-m-nav">
        <Offcanvas.Header closeVariant="white" closeButton>
          <Offcanvas.Title>创作者社区-问答</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="ee-search">
            <Form action="/search" className="w-100" onSubmit={handleSearch}>
              <FormControl
                type="search"
                placeholder={t('header.search.placeholder')}
                className="placeholder-search"
                value={searchStr}
                name="q"
                onChange={(e) => handleInput(e.target.value)}
              />
            </Form>
          </div>
          <Nav className="me-auto ee-menu">
            <Nav.Link href={getMenuUrl('')} target="_blank">
              社区
            </Nav.Link>
            <Nav.Link href={getMenuUrl('/Games/All')} target="_blank">
              游戏
            </Nav.Link>
            <Nav.Link href={getMenuUrl('/Teams')} target="_blank">
              团队
            </Nav.Link>
            <Nav.Link href={getMenuUrl('/Creation/All')} target="_blank">
              作品
            </Nav.Link>
            <Nav.Link href="https://www.vipskill.com" target="_blank">
              课程
            </Nav.Link>
            <Nav.Link className="ee-active" href="#">
              问答
            </Nav.Link>
            <Nav.Link href={getMenuUrl('/wiki')} target="_blank">
              知识库
            </Nav.Link>
            <div className="ee-line">
              <span />
            </div>
            {user?.username ? (
              <>
                <Nav.Link>
                  <Avatar
                    size="36px"
                    avatar={user?.avatar}
                    alt={user?.display_name}
                    searchStr="s=96"
                  />
                </Nav.Link>
                <NavLink
                  to={askUrl}
                  onClick={(e) => {
                    handleNavClick(e, askUrl);
                  }}>
                  {t('btns.add_question')}
                </NavLink>
                <NavLink
                  to="/users/notifications/inbox"
                  title={t('inbox', { keyPrefix: 'notifications' })}
                  onClick={(e) => {
                    handleNavClick(e, '/users/notifications/inbox');
                  }}>
                  收件箱
                  {(redDot?.inbox || 0) > 0 && (
                    <div className="unread-dot bg-danger">
                      <span className="visually-hidden">
                        {t('new_alerts', { keyPrefix: 'notifications' })}
                      </span>
                    </div>
                  )}
                </NavLink>

                <NavLink
                  to="/users/notifications/achievement"
                  title={t('achievement', { keyPrefix: 'notifications' })}
                  onClick={(e) => {
                    handleNavClick(e, '/users/notifications/achievement');
                  }}>
                  成就
                  {(redDot?.achievement || 0) > 0 && (
                    <div className="unread-dot bg-danger">
                      <span className="visually-hidden">
                        {t('new_alerts', { keyPrefix: 'notifications' })}
                      </span>
                    </div>
                  )}
                </NavLink>
                <NavLink
                  to={`/users/${user.username}`}
                  onClick={(e) => {
                    handleNavClick(e, `/users/${user.username}`);
                  }}>
                  {t('header.nav.profile')}
                </NavLink>
                <NavLink
                  to={`/users/${user.username}/bookmarks`}
                  onClick={(e) => {
                    handleNavClick(e, `/users/${user.username}/bookmarks`);
                  }}>
                  {t('header.nav.bookmark')}
                </NavLink>
                <NavLink
                  to="/users/settings/notify"
                  onClick={(e) => {
                    handleNavClick(e, '/users/settings/notify');
                  }}>
                  {t('header.nav.setting')}
                </NavLink>
                <Nav.Link onClick={handleLogout}>
                  {t('header.nav.logout')}
                </Nav.Link>
              </>
            ) : (
              <Button
                variant="link"
                className="ee-login"
                onClick={() => {
                  window.location.replace(
                    '/answer/api/v1/connector/login/basic',
                  );
                }}>
                登录 / 注册
              </Button>
            )}
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default memo(Header);
